/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Upload, Form, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
const { Dragger } = Upload;
const EditDrawing = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [drawings, setDrawings] = useState([]);
  const [formFields, setFormFields] = useState({
    description: "",
    price: "",
  });
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };
  const runOnStart = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://perfect-shelters-backend.onrender.com/drawing/get-drawings/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        const productData = response.data.data;

        setFormFields({
          description: productData?.description,
          price: productData?.price,
        });

        setDrawings(productData);
      } else {
        console.log(
          `Failed to fetch product data with status ${response.status}`
        );
        toast.error("Failed to fetch product data");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      console.log("drawing", drawings);
    }
  };
  useEffect(() => {
    runOnStart();
    console.log("drawing", drawings);
  }, []);
  const submit = async () => {
    console.log("new form", formFields);
    // e.preventDefault();

    setIsLoading(true);
    let finalData = {
      description: formFields.description,
      price: formFields.price,
    };
    try {
      const response = await axios.post(
        // `https://ferdi-be.onrender.com/drawing/edit-drawing/${id}`,
        `https://perfect-shelters-backend.onrender.com/drawing/edit-drawing/${id}`,
        finalData
      );

      if (response.status === 200) {
        console.log(response);
        toast.success("Product Edited successfully");
        navigate("/all-drawings");
        // Set loading state to false when the form submission completes (success or error)
      } else {
        console.log(`Form submission failed with status ${response}`);
        toast.error("Form submission failed", response);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="mt-10 px-10 ">
      <ToastContainer />
      <Form className="gap-10 flex flex-col" onFinish={submit}>
        <div className=" flex flex-col md:flex-row gap-4">
          <Form.Item
            className="md:w-[50%] w-full"
            label="Description"
            rules={[{ required: true, message: "Description is required" }]}
            labelCol={{ span: 24 }}
          >
            <input
              required
              type="text"
              id="description"
              name="description"
              value={formFields.description}
              placeholder="Enter Description"
              onChange={handleFieldChange}
              className="mt-1 p-2 rounded-md w-full bg-[#E8E8E8] focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
          <Form.Item
            className="md:w-[50%] w-full"
            label="Price"
            rules={[{ required: true, message: "Price is required" }]}
            labelCol={{ span: 24 }}
          >
            <input
              required
              type="text"
              id="price"
              name="price"
              placeholder="Enter Price"
              value={formFields.price}
              onChange={handleFieldChange}
              className="mt-1 p-2 rounded-md w-full bg-[#E8E8E8] focus:ring-indigo-500 focus:border-indigo-500"
            />
          </Form.Item>
        </div>

        <div className=" flex flex-col md:flex-row gap-4"></div>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="text-black">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditDrawing;
